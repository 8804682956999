import { createSlice } from "@reduxjs/toolkit";

const vehiclesSlice = createSlice({
  name: "vehiclesLists",
  initialState: {
    loading: false,
  },
  reducers: {
    vehiclesListsRequest(state, action) {
      return {
        loading: true,
      };
    },
    vehiclesListsSuccess(state, action) {
      return {
        loading: false,
        vehicles: action.payload.allVehiclelist,
        vehiclesCount: action.payload.count,
        resPerPage: action.payload.resPerPage
      };
    },
    vehiclesListsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = vehiclesSlice;

export const { vehiclesListsRequest ,vehiclesListsSuccess,vehiclesListsFail } = actions;

export default reducer;