import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import './Register.css';
import { register } from '../../../actions/userAction';

function AdminRegister() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [passwordError, setPasswordError] = useState('');
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    dispatch(register(userData));
    setPasswordError(''); // Clear any previous error
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h2>Register</h2>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Username"
              value={userData.name}
              onChange={handleInputChange}
              required
              style={{paddingLeft:"2.5rem"}}
            />
            <span className="icon user-icon"></span>
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={userData.email}
              onChange={handleInputChange}
              required
            />
            <span className="icon email-icon"></span>
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={userData.password}
              onChange={handleInputChange}
              required
            />
            <span className="icon eye-icon"></span>
          </div>
          <div className="form-group">
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={userData.confirmPassword}
              onChange={handleInputChange}
              required
            />
            <span className="icon eye-icon"></span>
          </div>
          {passwordError && <p className="error">{passwordError}</p>}
          <button type="submit" className="register-button" style={{ background: '#009970' }}>
            Register
          </button>
          <div className="login-link">
            <span>Already have an account? </span>
            <Link to="/AdminLogin">Login Here</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminRegister;
