import React from 'react'
import { Link } from 'react-router-dom';

function Donation() {
  return (
    <div>
      <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Donation</h2>
          <ol>
            <li>
              <Link to="/home"  style={{color:"#009970"}}>Home</Link>
            </li>
            <li>Donation</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    <section className="inner-page">
      <div className="container">
        <h2>Bank Account Details For Donation:</h2>
        <ul>
          <li>
            <strong>Name:</strong> IndianDriversSociety
          </li>
          <li>
            <strong>Account No.:</strong> 40675241065
          </li>
          <li>
            <strong>Bank Name:</strong> State Bank of India
          </li>
          <li>
            <strong>IFSC Code:</strong> SBIN0070323
          </li>
        </ul>
        <p>SCAN UPI FOR DONATIONS</p>
        <img
          className="QR-img"
          style={{ width: 300, height: 400 }}
          src="assets/img/QR-code.jpeg"
          alt="QR CODE"
        />
      </div>
    </section>
    </div>
  )
}

export default Donation;
