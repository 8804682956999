import "./App.css";
import Home from "./components/pages/Home";
import { Route, Routes } from "react-router-dom";
import About from "./components/pages/About";
import Donation from "./components/pages/Donation";
import Registration from "./components/pages/Registration";
import ContactUs from "./components/pages/ContactUs";
import AdminLogin from "./components/pages/authPages/AdminLogin";
import AdminRegister from "./components/pages/authPages/AdminRegister";
import Dashboard from "./components/pages/authPages/Dashboard";
import VehiclesList from "./components/pages/authPages/VehiclesList";
import MembersList from "./components/pages/authPages/MembersList";
import DetailedMemberView from "./components/pages/authPages/DetailedMemberView";
import DetailedVehicleView from "./components/pages/authPages/DetailedVehicleView";
import ProtectedRoute from "./components/Routes/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VehicleSuccess from "./components/pages/VehicleSuccess";
import MemberSuccess from "./components/pages/MemberSuccess";
import HomeLayout from "./components/layouts/HomeLayout";


function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <ToastContainer />
      
      <Routes>
        
        <Route path="/" element={<HomeLayout><Home /></HomeLayout>} />
        <Route path="/home" element={<HomeLayout><Home /></HomeLayout>} />
        <Route path="/about" element={<HomeLayout><About /></HomeLayout>} />
        <Route path="/donation" element={<HomeLayout><Donation /></HomeLayout>} />
        <Route path="/registration" element={<HomeLayout><Registration /></HomeLayout>} />
        <Route path="/contact" element={<HomeLayout><ContactUs /></HomeLayout>} />
        <Route path="/vehicle/success" element={<HomeLayout><VehicleSuccess /></HomeLayout>} />
        <Route path="/member/success" element={<HomeLayout><MemberSuccess /></HomeLayout>} />
        <Route path="/adminlogin" element={<HomeLayout><AdminLogin /></HomeLayout>} />
        <Route path="/adminregister" element={<HomeLayout><AdminRegister /></HomeLayout>} />
        <Route path="/c-panel" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/c-panel/vehicles_list" element={<ProtectedRoute><VehiclesList /></ProtectedRoute>} />
        <Route path="/c-panel/vehicle/:id" element={<ProtectedRoute><DetailedVehicleView /></ProtectedRoute>} />
        <Route path="/c-panel/members_list" element={<ProtectedRoute><MembersList /></ProtectedRoute>} />
        <Route path="/c-panel/member/:id" element={<ProtectedRoute><DetailedMemberView /></ProtectedRoute>} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}



export default App;
