import React from "react";
import qr from "../../assects/indiandriversocietysbi_page-0001.jpg";
import { Link } from "react-router-dom";

function MemberSuccess() {
  return (
    <div
      style={{
        marginTop: "7rem",
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
    <h2>Thank you for Registering.</h2>
      <div style={{ textAlign: "center" }}>
        <h2>Payment Options:</h2>
        <div>
          <p>Scan the QR code to make payment</p>
          <img style={{ maxWidth: "350px" }} src={qr} alt="QR code" />
        </div>

        <div>
          <p>
            Pay <strong> ₹250/- </strong> to the following <br />
            Account number: 40675241065 <br />
            IFSC Code : SBIN0070323
          </p>
        </div>
      </div>
      <Link to="/">Back to Home</Link>
    </div>
  );
}

export default MemberSuccess;
