import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { getVehicle } from "../../../actions/vehicleActions";
import { useReactToPrint } from "react-to-print";
import Loader from "../../layouts/Loader";
import "./Dashboard.css";
import { Dropdown } from "react-bootstrap";
import { BsPersonFill } from "react-icons/bs";
import sealImg from "./assets/seal.png";

function DetailedVehicleView() {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { loading,vehicleList } = useSelector((state) => state.vehicleState);

  // const vehicleList = {
  //   vehicleNumber: 'KL-07-AB-1234',
  //   vehicleModel: 'Maruti Suzuki Swift',
  //   vehicleMake: 'Maruti Suzuki',
  //   rcOwnerName: 'John Doe',
  //   ownerAddress: '1234 Elm Street, Kochi, Kerala',
  //   ownerContact: '+91-9876543210',
  //   nearestPoliceStation: 'Kochi City Police Station',
  // };
  
  useEffect(() => {
    dispatch(getVehicle(id));
  }, [dispatch, id]);

  return (
    <div className="container-fluid">
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Logo */}
          <div className="logo">
            <h1 className="text-light">IndianDriversSociety</h1>
          </div>

          {/* Profile Dropdown */}
          <Dropdown alignRight>
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              className="dropdown-item"
            >
              <BsPersonFill className="profile-pic" />
              <span>ADMIN</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/c-panel">
                Profile <i className="bi bi-person"></i>
              </Dropdown.Item>
              <Dropdown.Item href="/AdminLogin">
                Logout <i className="bi bi-box-arrow-right"></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <div className="row">
        {/* Sidebar */}
        <aside className="col-lg-3 sidebar">
          <div className="profile">
            <h1 className="text-center bold">Vehicle Details</h1>
          </div>
          <nav className="text-center dashboard-nav">
            <ul>
              <li>
                <Link to="/c-panel">Dashboard</Link>
              </li>
              <li>
                <Link to="/c-panel/members_list">Membership List</Link>
              </li>
              <li>
                <Link to="/c-panel/vehicles_list">Vehicles List</Link>{" "}
                <ul>
                  <li>
                    <Link to="" className="active">
                      Vehicle Details
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="col-lg-9 main-content">
          {loading ? (
            <Loader />
          ) : (
            <div className="memberview bordered-content">
              <div className="container bordered-content-inside p-3 m-4" ref={componentRef}>
                <div className="logo-section d-flex justify-content-start align-items-center mb-4">
                  <img
                    className="logo-img"
                    src="/assets/img/IDS_logo.png"
                    alt="logo"
                  />
                  <h3 id="member" className="ms-3 mb-0">
                    IndianDriversSociety
                  </h3>
                </div>

                {/* <h5 id="vehicle">Vehicle Information</h5> */}
                <div>
                  <table className="member-table bordered-table">
                    <tbody>
                      <tr>
                        <th>Vehicle Number</th>
                        <td>:&nbsp;{vehicleList.vehicleNumber}</td>
                      </tr>
                      <tr>
                        <th>Vehicle Model</th>
                        <td>:&nbsp;{vehicleList.vehicleModel}</td>
                      </tr>
                      <tr>
                        <th>Vehicle Make</th>
                        <td>:&nbsp;{vehicleList.vehicleMake}</td>
                      </tr>
                      <tr>
                        <th>Owner Name</th>
                        <td>:&nbsp;{vehicleList.rcOwnerName}</td>
                      </tr>
                      <tr>
                        <th>Owner Address</th>
                        <td>:&nbsp;{vehicleList.ownerAddress}</td>
                      </tr>
                      <tr>
                        <th>Owner Contact</th>
                        <td>:&nbsp;{vehicleList.ownerContact}</td>
                      </tr>
                      <tr>
                        <th>Nearest Police Station</th>
                        <td>:&nbsp;{vehicleList.nearestPoliceStation}</td>
                      </tr>

                    </tbody>
                  </table>
                      <div className="right-aligned mt-5">
                        <img className="sealImg" src={sealImg} alt="logo" />
                      </div>
                </div>
              </div>
              <div className="edit-download-btn container">
                <button
                  className="submit-btn"
                  type="button"
                  onClick={handlePrint}
                >
                  <i className="bi bi-download"></i> DOWNLOAD
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default DetailedVehicleView;
