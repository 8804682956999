import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { thunk } from 'redux-thunk'; // Import thunk as a named export

import authReducer from './slices/authSlice';
import vehichleReducer from './slices/vehicleSlice'
import vehichlesReducer from './slices/vehiclesSlice'
import memberReducer from './slices/memberSlice'
import membersReducer from './slices/membersSlice'

const reducer = combineReducers({
    authState: authReducer,
    vehicleState: vehichleReducer,
    vehiclesState: vehichlesReducer,
    memberState: memberReducer,
    membersState: membersReducer,
});

const middleware = (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    thunk, // Add thunk middleware to the store
];

const store = configureStore({
    reducer: reducer,
    middleware: middleware, // Add thunk middleware to the store
});

export default store;

