import React, { useState } from "react";
import "./HomeStyle.css";
import { createMember } from "../../actions/memberActions";
import { createVehicle } from "../../actions/vehicleActions";
import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [showMemberForm, setShowMemberForm] = useState(true);
  const [showVehicleForm, setShowVehicleForm] = useState(false);
  const [memberData, setMemberData] = useState({
    drivername: "",
    aadharCardNumber: "",
    drivingLicenseNumber: "",
    contactNumber: "",
    email: "",
    addressLineOne: "",
    district: "",
    state: "",
    postalCode: "",
    country: "India",
  });
  const [vehicleData, setVehicleData] = useState({
    userEmail:"",
    vehicleNumber: "",
    vehicleMake: "",
    vehicleModel: "",
    rcOwnerName: "",
    ownerAddress: "",
    ownerContact: "",
    nearestPoliceStation: "",
  });


  const handleMemberFormClick = () => {
    setShowMemberForm(true);
    setShowVehicleForm(false);
  };

  const handleVehicleFormClick = () => {
    setShowMemberForm(false);
    setShowVehicleForm(true);
  };

  const handleMemberInputChange = (e) => {
    const { name, value } = e.target;
    setMemberData({ ...memberData, [name]: value });
  };

  const handleVehicleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleData({ ...vehicleData, [name]: value });
  };

  const handleMemberSubmit = async (e) => {
    e.preventDefault();
    console.log('memberData', memberData)
    dispatch(createMember(memberData,navigate));
  };

  const handleVehicleSubmit = async (e) => {
    e.preventDefault();
    console.log('memberData', vehicleData)
    dispatch(createVehicle(vehicleData,navigate));
  };

  

  return (
    <div>
      {/* ======= Breadcrumbs ======= */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Registration</h2>
            <ol>
              <li>
                <Link href="/home"style={{color:"#009970"}}>Home</Link>
              </li>
              <li>Registration</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Breadcrumbs */}

      <section className="registration">
        <div className="container">
          <h1>Registration Forms</h1>
          <p className="text-center">
            Looking for a like-minded group? Join our guild by filling out the
            form and start your journey today!
          </p>
          <div className="toggle-buttons">
            <button
              id="member-form-btn"
              className={showMemberForm ? "active" : ""}
              onClick={handleMemberFormClick}
            >
              Member Registration
            </button>
            <button
              id="vehicle-form-btn"
              className={showVehicleForm ? "active" : ""}
              onClick={handleVehicleFormClick}
            >
              Vehicle Registration
            </button>
          </div>
        </div>
      </section>

      <section className="container">
        {showMemberForm && (
          <form onSubmit={handleMemberSubmit}>
            <h1 style={{ marginTop: "3%" }} className="form-heading">
              Member Registration
            </h1>
            {/* Member registration form fields */}
            <div className="form-group">
              <label htmlFor="drivername">Driver Name <span className="star">*</span></label>
              <input
                type="text"
                id="drivername"
                name="drivername"
                value={memberData.drivername}
                onChange={handleMemberInputChange}
                required
                placeholder="Enter Full Name"
                title="Enter Your Full Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="aadharCardNumber">Aadhaar Card Number <span className="star">*</span></label>
              <input
                type="text"
                id="aadharCardNumber"
                name="aadharCardNumber"
                value={memberData.aadharCardNumber}
                onChange={handleMemberInputChange}
                required
                minLength="12"
                maxLength="12"
                placeholder="Enter 12 Digit Aadhar Number"
                title="Aadhaar Card Number must be 12 digits long"
                pattern="\d{12}"
              />
            </div>
            <div className="form-group">
              <label htmlFor="drivingLicenseNumber">Driving License Number <span className="star">*</span></label>
              <input
                type="text"
                id="drivingLicenseNumber"
                name="drivingLicenseNumber"
                value={memberData.drivingLicenseNumber}
                onChange={handleMemberInputChange}
                required
                placeholder="Enter Driving License Number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="contactNumber">Contact Number <span className="star">*</span></label>
              <input
                type="text"
                id="contactNumber"
                name="contactNumber"
                value={memberData.contactNumber}
                onChange={handleMemberInputChange}
                required
                minLength="10"
                maxLength="10"
                placeholder="Enter 10 Digit Contact Number"
                pattern="\d{10}"
                title="Contact Number must be 10 digits long"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email <span className="star">*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={memberData.email}
                onChange={handleMemberInputChange}
                required
                placeholder="Enter Your Email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Please enter a valid email address(e.g.,example@gmail.com)"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="addressLineOne">Address <span className="star">*</span></label>
              <input
                type="text"
                id="addressLineOne"
                name="addressLineOne"
                value={memberData.addressLineOne}
                onChange={handleMemberInputChange}
                required
                placeholder="Enter Your Address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="district">District</label>
              <input
                type="text"
                id="district"
                name="district"
                value={memberData.district}
                onChange={handleMemberInputChange}
                placeholder="Enter Your District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State <span className="star">*</span></label>
              <input
                type="text"
                id="state"
                name="state"
                value={memberData.state}
                onChange={handleMemberInputChange}
                required
                placeholder="Enter Your State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                type="number"
                id="postalCode"
                name="postalCode"
                value={memberData.postalCode}
                onChange={handleMemberInputChange}
                required
                placeholder="Enter Your Postal Code"
              />
            </div>
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={memberData.country}
                onChange={handleMemberInputChange}
                disabled
              />
            </div>
            <div className="form-group">
              <button className="submit-btn" type="submit">
                SUBMIT
              </button>
            </div>
          </form>
        )}

        {showVehicleForm && (
          <form onSubmit={handleVehicleSubmit}>
            <h1 style={{ marginTop: "3%" }} className="form-heading">
              Vehicle Registration
            </h1>
            {/* Vehicle registration form fields */}
            <div className="form-group">
              <label htmlFor="vehicleNumber">Vehicle Number <span className="star">*</span></label>
              <input
                type="text"
                id="vehicleNumber"
                name="vehicleNumber"
                value={vehicleData.vehicleNumber}
                onChange={handleVehicleInputChange}
                required
                placeholder="Enter Your Vehicle Number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="vehicleMake">Vehicle Make <span className="star">*</span></label>
              <input
                type="text"
                id="vehicleMake"
                name="vehicleMake"
                value={vehicleData.vehicleMake}
                onChange={handleVehicleInputChange}
                required
                placeholder="Enter Vehicle Make"
              />
            </div>
            <div className="form-group">
              <label htmlFor="vehicleModel">Vehicle Model <span className="star">*</span></label>
              <input
                type="text"
                id="vehicleModel"
                name="vehicleModel"
                value={vehicleData.vehicleModel}
                onChange={handleVehicleInputChange}
                required
                placeholder="Enter Vehicle Model"
              />
            </div>
            <div className="form-group">
              <label htmlFor="rcOwnerName">RC Owner Name <span className="star">*</span></label>
              <input
                type="text"
                id="rcOwnerName"
                name="rcOwnerName"
                value={vehicleData.rcOwnerName}
                onChange={handleVehicleInputChange}
                required
                placeholder="Enter RC Owner Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email <span className="star">*</span></label>
              <input
                type="email"
                id="userEmail"
                name="userEmail"
                value={vehicleData.userEmail}
                onChange={handleVehicleInputChange}
                required
                placeholder="Enter Your Email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Please enter a valid email address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="ownerAddress">Owner Address <span className="star">*</span></label>
              <input
                type="text"
                id="ownerAddress"
                name="ownerAddress"
                value={vehicleData.ownerAddress}
                onChange={handleVehicleInputChange}
                required
                placeholder="Enter Your Address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="ownerContact">Owner Contact <span className="star">*</span></label>
              <input
                type="text"
                id="ownerContact"
                name="ownerContact"
                value={vehicleData.ownerContact}
                onChange={handleVehicleInputChange}
                required
                minLength="10"
                maxLength="10"
                placeholder="Enter 10 Digit Contact Number"
                pattern="\d{10}"
                title="Contact Number must be 10 digits long"
              />
            </div>
            <div className="form-group">
              <label htmlFor="nearestPoliceStation">Nearest Police Station</label>
              <input
                type="text"
                id="nearestPoliceStation"
                name="nearestPoliceStation"
                value={vehicleData.nearestPoliceStation}
                onChange={handleVehicleInputChange}
                placeholder="Enter Nearest Police Station"
              />
            </div>
            <div className="form-group">
              <button className="submit-btn" type="submit">
                SUBMIT
              </button>
            </div>
          </form>
        )}
      </section>
    </div>
  );
}

export default Registration;
