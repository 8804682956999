import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../layouts/Loader";
import { useEffect } from "react";
import { authenticateUser } from "../../actions/userAction";

export default function ProtectedRoute({ children, isAdmin }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticateUser());
  }, [dispatch]);
  const { isAuthenticated, loading } = useSelector(
    (state) => state.authState
  );

  if (loading) {
    return <Loader />;
  }

  if (!isAuthenticated && !loading) {
    return <Navigate to="/adminlogin" />;
  }

  if (isAuthenticated) {
    return children;
  }
}
