import {
  deleteMemberFail,
  deleteMemberRequest,
  deleteMemberSuccess,
  memberFail,
  memberRequest,
  memberSuccess,
  newMemberFail,
  newMemberRequest,
  newMemberSuccess,
  updateMemberFail,
  updateMemberRequest,
  updateMemberSuccess,
} from "../slices/memberSlice";
import {
  membersListsFail,
  membersListsRequest,
  membersListsSuccess,
} from "../slices/membersSlice";
import { apiClient } from "./utils";

// export const apiClient = axios.create({
//       baseURL: "https://api.indiandriverssociety.in/",
//     headers: {
//         "Content-Type": "application/json",
//     }
// }); 

export const getMember = (id) => async (dispatch) => {
  try {
    dispatch(memberRequest());
    const response = await apiClient.get(`/api/v1/member/${id}`);
    const data = response.data; // Access the data from the response object
    dispatch(memberSuccess(data));
  } catch (error) {
    dispatch(memberFail(error));
  }
};

export const getMemberList = (keyword, currentPage) => async (dispatch) => {
  try {
    dispatch(membersListsRequest());
    let link = `/api/v1/member?page=${currentPage}`;

    if (keyword) {
      link += `&keyword=${keyword}`;
    }
    const { data } = await apiClient.get(link);
    dispatch(membersListsSuccess(data));
  } catch (error) {
    dispatch(membersListsFail(error.response.data.message));
  }
};

export const createMember = (memberData, navigate) => async (dispatch) => {
  try {
    dispatch(newMemberRequest());
    const { data } = await apiClient.post(
      "/api/v1/member/register",
      memberData
    );
    dispatch(newMemberSuccess(data));
    navigate("/member/success");
  } catch (error) {
    dispatch(newMemberFail(error.response.data.message));
  }
};

export const updateMember = (id, memberData) => async (dispatch) => {
  try {
    dispatch(updateMemberRequest());
    const { data } = await apiClient.put(`/api/v1/member/${id}`, memberData);
    dispatch(updateMemberSuccess(data));
  } catch (error) {
    dispatch(updateMemberFail(error.response.data.message));
  }
};

export const deleteMember = (id) => async (dispatch) => {
  try {
    dispatch(deleteMemberRequest());
    await apiClient.delete(`/api/v1/member/${id}`);
    dispatch(deleteMemberSuccess());
  } catch (error) {
    dispatch(deleteMemberFail(error.response.data.message));
  }
};
